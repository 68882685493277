import React, { ReactEventHandler, useState } from "react";
import styles from "./TopScreen.module.scss";
import Translate from "../base/Translate/Translate";

function TopScreen() {
  const [info, changeInfo]: any = useState([
    { count: 5, description: "countPlayers", style: styles.Registered },
    { count: 5, description: "oborot", style: styles.Head },
    { count: 5, description: "totalAmoutTon", style: styles.Rnd },
    { count: 5, description: "totalAmoutTon", style: styles.Crafted },
  ]);
  return (
    <div className={styles.TopScreen}>
      <div className={`${styles.TopScreenContainer} container`}>
        <div className={styles.TopScreenMainInfo}>
          <h1 className={styles.TopScreenTitle}>
            <Translate dictKey="title" />
          </h1>
          <div className={styles.TopScreenDescription}>
            <Translate dictKey="titleDescription" />
            <br />
            <b className={styles.TopScreenDescriptionAdditionalDescription}>
              Please note: this game is still in development, so some
              information may be outdated and is subject to major updates.
              Earlier there was information about launching our own
              cryptocurrency, most likely we will not create our own
              cryptocurrency, but will use existing technologies, the TON base
              is quite suitable for solving all problems
            </b>
          </div>
        </div>

        <div className={`${styles.TopScreenInfo}`}>
          {info.map(({ count, description, style }: any, idx: number) => {
            return (
              <div key={idx} className={`${styles.TopScreenInfoItem}`}>
                <div
                  className={`${styles.TopScreenInfoItemImage} ${style}`}
                ></div>
                <div
                  className={`${styles.TopScreenInfoItemCount} text-main-color`}
                >
                  {count}
                </div>
                <div className={styles.TopScreenInfoItemDesc}>
                  <Translate dictKey={description} />
                </div>
              </div>
            );
          })}
        </div>
        {/* <div className={styles.ExchangeInfo}>
          <div className={styles.ExchangeInfoTitle}>
            <h2>
              Presale{" "}
              <span>
                (<Translate dictKey="comingSoon" />)
              </span>
            </h2>
            <span>Total — 747.32 | 1 TON — 0.0189</span>
          </div>

          <div className={styles.ExchangeInfoForm}>
            <div className="input-proj-container">
              <label className="form-label" htmlFor="formGroupExampleInput">
                <Translate dictKey="from" />
              </label>
              <div className="input-group mb-3">
                <input
                  aria-describedby="basic-addon2"
                  aria-label="Recipient's username"
                  className="form-control bg-none input-proj"
                  disabled={true}
                  id="formGroupExampleInput"
                  placeholder="Amount ton"
                  type="text"
                />
                <span
                  className="input-group-text bg-none input-proj"
                  id="basic-addon2"
                >
                  TON
                </span>
              </div>
            </div>
            <div className="input-proj-container">
              <label className="form-label" htmlFor="formGroupExampleInput1">
                <Translate dictKey="to" />
              </label>
              <div className="input-group mb-3">
                <input
                  aria-describedby="basic-addon2"
                  aria-label="Recipient's username"
                  className="form-control bg-none input-proj"
                  disabled={true}
                  id="formGroupExampleInput1"
                  placeholder="Amount"
                  type="text"
                />
                <span
                  className="input-group-text bg-none input-proj"
                  id="basic-addon2"
                >
                  ????
                </span>
              </div>
            </div>
            <button className={"btn btn-main-outline px-4"} disabled={true}>
              <Translate dictKey="buy" />
            </button>
            <button className={"btn btn-main-outline px-4"} disabled={true}>
              <Translate dictKey="comingSoon" />
            </button>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default TopScreen;
